import { render, staticRenderFns } from "./FastNotifications.vue?vue&type=template&id=401df071&scoped=true&"
import script from "./FastNotifications.vue?vue&type=script&lang=js&"
export * from "./FastNotifications.vue?vue&type=script&lang=js&"
import style0 from "./FastNotifications.vue?vue&type=style&index=0&id=401df071&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "401df071",
  null
  
)

export default component.exports