<template>
  <div class="FastNotifications">
    <transition-group name="fade">
      <div
        class="FastNotifications__item"
        v-for="({ id, icon, text }, index) of notifications"
        :key="id"
        @click="REMOVE_NOTIFICATION(index)"
      >
        <div
          v-if="icon"
          class="FastNotifications__itemIconWrap"
        >
          <VIcon
            v-if="icon === 'warning'"
            name="warning"
            color="red"
            :size="24"
          />
        </div>

        <span class="FastNotifications__itemText">{{ text }}</span>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import VIcon from '@/components/ui/VIcon';

export default {
  name: 'FastNotifications',
  components: {
    VIcon,
  },

  computed: {
    ...mapState('fastNotification', [
      'notifications',
    ]),
  },

  methods: {
    ...mapMutations('fastNotification', [
      'REMOVE_NOTIFICATION',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s, transform .3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.FastNotifications {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 15px 25px;
  pointer-events: none;
  z-index: 9999;

  // Уведомление
  &__item {
    display: flex;
    width: max-content;
    max-width: 364px;
    align-items: center;
    margin: 8px auto;
    padding: 10px 17px;
    border-radius: 4px;
    background-color: #202430;
    box-shadow: 0 4px 13px rgba(48, 48, 48, 0.1);
    pointer-events: all;
    cursor: pointer;
  }

  &__itemIconWrap {
    margin-right: 15px;
  }

  &__itemText {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 300;
    word-break: break-word;
  }
}
</style>
